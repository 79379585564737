import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { useTranslation } from "gatsby-plugin-react-i18next"
import thy1 from "../../assets/img/thy1.png"
import thy2 from "../../assets/img/thy-yeni-2.png"
import thy3 from "../../assets/img/thy3.png"
import thy4 from "../../assets/img/thy-yeni-1.png"
import thy5 from "../../assets/img/thy-5.png"
import thy6 from "../../assets/img/thy-6.png"

export default function THYProjesi() {
  const { t } = useTranslation()
  return (
    <Layout>
      <SEO title="Türk Hava Yolları Projesi" />
      <div className="projeler-container page">
        <div className="ic-proje">
          <img src={thy3} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("turk-hava-yollari")}
            <br />
            <span>{t("project-sector")}</span>: {t("aerospace")}
            <br />
            <span>{t("project-subject")}</span>: {t("logonun-yorumu")}
            <br />
            <span>{t("project-artwork")}</span>: {t("allahcc")}
          </div>
          <img src={thy4} alt="" className="proje-icGorsel" />
        </div>
        <div className="seperator"></div>
        <div className="ic-proje">
          <img src={thy1} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("turk-hava-yollari")}
            <br />
            <span>{t("project-sector")}</span>: {t("aerospace")}
            <br />
            <span>{t("project-subject")}</span>: {t("ucagin-yorumu")}
            <br />
            <span>{t("project-artwork")}</span>: {t("allah-cc-tekrari")}
          </div>
          <img src={thy2} alt="" className="proje-icGorsel" />
        </div>
        <div className="seperator"></div>
        <div className="ic-proje">
          <img src={thy5} alt="" className="proje-icGorsel" />
          <div className="proje-kunye">
            <span>{t("project-brand")}</span>: {t("turk-hava-yollari")}
            <br />
            <span>{t("project-sector")}</span>: {t("aerospace")}
            <br />
            <span>{t("project-subject")}</span>: {t("tav-galeri")}<br />{t("tav-galeri1")}
            <br />
            <span>{t("project-artworkTR")}</span>: {t("ozel-koleksiyonTR")}
          </div>
          <img src={thy6} alt="" className="proje-icGorsel" />
        </div>
      </div>
    </Layout>
  )
}
